import React, { useState, useEffect } from "react";
import AppData from "../../../config/appData.json";
import GetData from "../../../api/GetData";

export default function UserProfile({ userId, setShowUserData }) {
  const [user, setUser] = useState({});

  const getUserProfile = async () => {
    try {
      const url = AppData.WALLET_URL + `admin/user/${userId}`;
      const { user, wallet } = await GetData(url);
      setUser(user);
    } catch (error) {
      console.error("Error Fetching user profile", error);
    }
  };

  useEffect(() => {
    getUserProfile();
  }, []);

  const UserDetails = ({ heading, name }) => (
    <div className="col-3">
      <div>
        <h4>{heading}</h4>
        <p className="data-info">{name}</p>
      </div>
    </div>
  );
  return (
    <div className="profiled-user mt-3">
      <button
        className="back-btn"
        type="button"
        onClick={() => setShowUserData(false)}
      >
        <i className="fa-solid fa-arrow-left"></i>Back
      </button>
      <div className="user-data">
        <div className="section-header">
          User Details
          <div className="col-3">
            <h4>KYC Document</h4>
            <p className={user.isKycDocument ? "data-info" : "data-info-error"}>
              {user.isKycDocument ? "Available" : "Not Available"}
            </p>
          </div>
        </div>
        <div className="row">
          <UserDetails heading="Agent Type" name={user?.agentType} />
          <UserDetails heading="First name" name={user?.firstName} />
          <UserDetails heading="Last name" name={user?.lastName} />
          <UserDetails heading="Date of birth" name={user?.dateOfBirth} />
          <UserDetails heading="Company name" name={user?.companyName} />
          <UserDetails heading="State Of Origin" name={user?.stateOfOrigin} />
          <UserDetails heading="Local Govt Area" name={user?.localGovernment} />
          <UserDetails heading="Phone Number" name={user?.phoneNumber} />
          <UserDetails heading="Gender" name={user?.gender} />
          <UserDetails heading="Address" name={user?.address} />
        </div>
      </div>
      {user.isKycDocument && (
        <div className="row uploaded-document pb-5 border-bottom">
          <div className="col-2 mt-3 p-2">
            <p className="mt-2">Passport</p>
            <img src={user?.passport} alt="cac-document" />
          </div>
          <div className="col-4 mt-3 p-2">
            <p className="mt-2">ID Card</p>
            <img src={user?.idCard} alt="cac-document" />
          </div>
          <div className="col-6 mt-3 p-2">
            <p className="mt-2">Utility Bill</p>
            <img
              className="ms-2 w-100"
              src={user?.utilityBill}
              alt="cac-document"
            />
          </div>
          <div className="col-2 mt-3 p-2">
            <p className="mt-2">Guarantor's Passport</p>
            {user && <img src={user.guarantorsPassport} alt="cac-document" />}
          </div>
          <div className="col-4 mt-3 p-2">
            <p className="mt-2">Guarantor's ID Card</p>
            <img src={user?.guarantorsId} alt="cac-document" />
          </div>
          <div className="col-6 mt-3 p-2">
            <p className="mt-2">CAC Document</p>
            <img
              className="ms-2 w-50"
              src={user?.cacDocument}
              alt="cac-document"
            />
          </div>
        </div>
      )}
    </div>
  );
}
