import React, { useState, useEffect } from "react";
import CustomSelect from "../../../components/customSelect/CustomSelect";
import AppData from "../../../config/appData.json";
import handlePostApi from "../../../api/HandlePostApi";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

export default function RaiseChargeModal({ setShowModal, setMsg }) {
  const [selectedType, setSelectedType] = useState("");
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState("");
  const [selectedAction, setSelectedAction] = useState("");

  const schema = yup.object().shape({
    product: yup
      .string()
      .required("Prodccut is required!")
      .default(selectedProduct),
    type: yup.string().required("Type is required!").default(selectedType),
    action: yup
      .string()
      .required("Action is required!")
      .default(selectedAction),
    totalCommission: yup.string().required("Field is required!"),
    userCommission: yup.string().required("Field is required!"),
    aggregatorCommission: yup.string().required("Field is required!"),
    systemCommission: yup.string().required("Field is required!"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const getProducts = async () => {
    try {
      const endpoint = AppData.VAS_URL + "admin/get-all-products";
      const { products } = await handlePostApi(endpoint, {});
      setProducts(products);
    } catch (error) {
      console.log("Error fetching data", error);
    }
  };

  const createCharge = async (requestData = {}) => {
    try {
      console.log("requestData", requestData);
      const endpoint =
        AppData.VAS_URL + "admin/profile/create-transaction-charges";
      const response = await handlePostApi(endpoint, requestData);
      console.log("response", response);
      // setMsg(response.data.message);
      setShowModal(false);
    } catch (error) {
      console.error("Error Processing request", error);
    }
  };

  useEffect(() => {
    getProducts();
  }, []);

  return (
    <div className="raise-charge-modal">
      <form onSubmit={handleSubmit(createCharge)}>
        <div className="form-container">
          <div className="header">Raise Charge Here</div>
          <div className="row p-4">
            <div className="col-4 mb-3">
              <CustomSelect
                className="custom-select"
                heading="Select Charge Type"
                selectedVal={selectedType ? selectedType : "Select Type"}
                setSelectedValue={setSelectedType}
                items={[
                  {
                    name: "fixed",
                  },
                  {
                    name: "percentage",
                  },
                ]}
                defaultOption={{ label: "Select Type", value: "" }}
              />
            </div>
            <div className="col-4 mb-3">
              <CustomSelect
                className="custom-select"
                heading="Choose Product"
                selectedVal={
                  selectedProduct ? selectedProduct : "Select Product"
                }
                setSelectedValue={setSelectedProduct}
                items={products}
                defaultOption={{ label: "Select Product", value: "" }}
              />
            </div>
            <div className="col-4 mb-3">
              <CustomSelect
                className="custom-select"
                heading="Choose Action"
                selectedVal={selectedAction ? selectedAction : "Select Action"}
                setSelectedValue={setSelectedAction}
                items={[
                  {
                    name: "charge",
                  },
                  {
                    name: "commission",
                  },
                ]}
                defaultOption={{ label: "Select Action", value: "" }}
              />
            </div>
            <div className="col-4 mb-3">
              <div className="grouped-input">
                <label>Input Total Commission (₦)</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="0.00"
                  {...register("totalCommission")}
                />
              </div>
              <small>{errors.totalCommission?.message}</small>
            </div>
            <div className="col-4 mb-3">
              <div className="grouped-input">
                <label>Input User Commission (₦)</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="0.00"
                  {...register("userCommission")}
                />
              </div>
              <small>{errors.userCommission?.message}</small>
            </div>
            <div className="col-4 mb-3">
              <div className="grouped-input">
                <label>Input Aggregator Commission (₦)</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="0.00"
                  {...register("aggregatorCommission")}
                />
              </div>
              <small>{errors.aggregatorCommission?.message}</small>
            </div>
            <div className="col-4 mb-3">
              <div className="grouped-input">
                <label>Input System Commission (₦)</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="0.00"
                  {...register("systemCommission")}
                />
              </div>
              <small>{errors.systemCommission?.message}</small>
            </div>
            <div className="col-4 mb-3">
              <div className="grouped-input">
                <label>Capped Total Commission (₦)</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="0.00"
                  // {...register("cappedTotalCommission")}
                />
              </div>
              {/* <small>{errors.cappedTotalCommission?.message}</small> */}
            </div>
            <div className="col-4 mb-3">
              <div className="grouped-input">
                <label>Capped User Commission (₦)</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="0.00"
                  // {...register("cappedUserCommission")}
                />
              </div>
              {/* <small>{errors.cappedUserCommission?.message}</small> */}
            </div>
            <div className="col-4 mb-3">
              <div className="grouped-input">
                <label>Capped Aggregator Commission (₦)</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="0.00"
                  // {...register("cappedAggregatorCommission")}
                />
              </div>
              {/* <small>{errors.cappedAggregatorCommission?.message}</small> */}
            </div>
            <div className="col-4 mb-3">
              <div className="grouped-input">
                <label>Capped System Commission (₦)</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="0.00"
                  // {...register("cappedSystemCommission")}
                />
              </div>
              {/* <small>{errors.cappedSystemCommission?.message}</small> */}
            </div>
          </div>
        </div>
        <div className="form-footer">
          <div className="charge-action mt-3">
            <button className="action-btn cancel" onClick={handleCloseModal}>
              Cancel
            </button>
            <button className="action-btn create" type="submit">
              Create Charge
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
