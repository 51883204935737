import React, { useState, useEffect } from "react";
import CircleProgressBar from "../../../../components/circleProgressBar/CircleProgressBar";
import "./Dashboard.css";
import AppData from "../../../../config/appData.json";
import GetData from "../../../../api/GetData";
import FormatAmount from "../../../../helpers/FormatAmount";

export default function Dashboard() {
  const [status, setStatus] = useState("");
  const [activeTab, setActiveTab] = useState(0);
  const [date] = useState(new Date());
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const days = ["Today", "Yesterday", "Present Week"];
  var abbreviate = require("number-abbreviate");

  const handleDateFunc = (index) => {
    if (index === 0) {
      setStartDate(new Date());
      setEndDate(new Date());
    } else if (index === 1) {
      let yesterday = new Date(new Date().valueOf() - 1000 * 60 * 60 * 24);
      setStartDate(yesterday);
      setEndDate(yesterday);
    } else {
      let day = date.getDay();
      let diff = date.getDate() - day + (day === 0 ? -6 : 1);
      let firstDate = new Date(date.setDate(diff));
      setStartDate(firstDate);
      setEndDate(new Date());
    }
    setActiveTab(index);
  };

  const formattedDate =
    date.getFullYear() +
    "-" +
    parseInt(date.getMonth() + 1) +
    "-" +
    date.getDate();
  const formattedStartDate =
    startDate.getFullYear() +
    "-" +
    parseInt(startDate.getMonth() + 1) +
    "-" +
    startDate.getDate();
  const formattedEndDate =
    endDate.getFullYear() +
    "-" +
    parseInt(endDate.getMonth() + 1) +
    "-" +
    endDate.getDate();

  useEffect(() => {
    const fetchStatusData = async () => {
      try {
        const endpoint =
          AppData.BASE_URL +
          `transactions/stats?date=${formattedDate}&startdate=${formattedStartDate}&enddate=${formattedEndDate}`;
        const response = await GetData(endpoint);
        // console.log("trans", response)
        setStatus(response);
      } catch (error) {
        console.log("Error fetching data", error);
      }
    };
    fetchStatusData();
  }, [formattedStartDate, formattedEndDate]);

  return (
    <div className="dashboard">
      <div className="dashboard-param">
        <ul className="nav nav-pills">
          {days.map((day, index) => (
            <li
              key={index}
              className={index === activeTab ? "nav-link active" : "nav-link"}
              onClick={() => handleDateFunc(index)}
            >
              <button className="border-end">{day}</button>
            </li>
          ))}
        </ul>
      </div>
      <div className="row">
        <div className="col-4 text-center">
          <p className="summary-heading">Values</p>
          <div className="summary">
            <p className="summary-amount success">
              ₦{FormatAmount(status?.data?.success_value)}
            </p>
            <p className="success-percent">
              <span>{status.data && status.data.success_percent}%</span>
              Successful Transactions
            </p>

            <div className="circle-bar-area">
              <CircleProgressBar
                size={180}
                value={status.data && status.data.success_percent}
                strokeWidth={45}
                circleOneStroke="#DDDDDD"
                circleTwoStroke="#00865A"
                centerValue={abbreviate(status?.data?.total_value / 100)}
              />
            </div>
            <p className="summary-amount failed">
              ₦{FormatAmount(status?.data?.failed_value)}
            </p>
            <p className="failed-percent">
              <span>{status.data && status.data.failed_percent}%</span>
              Failed Transactions
            </p>
          </div>
        </div>
        <div className="col-4 text-center">
          <p className="summary-heading">Volumes</p>
          <div className="summary border-start border-end">
            <p className="summary-amount success-count">
              {status.data && status.data.success_count.toLocaleString()}
            </p>
            <p className="success-percent">
              <span>55%</span>
              Successful Transactions
            </p>
            <div className="circle-bar-area">
              <CircleProgressBar
                size={180}
                value={status.data && status.data.success_percent}
                strokeWidth={45}
                circleOneStroke="#DDDDDD"
                circleTwoStroke="#021623"
                centerValue={
                  status.data && abbreviate(status.data.total_volume)
                }
              />
            </div>
            <p className="summary-amount count-failed">
              {status.data && status.data.failed_count.toLocaleString()}
            </p>
            <p className="failed-percent">
              <span>{status.data && status.data.failed_percent}%</span>
              Failed Transactions
            </p>
          </div>
        </div>
        <div className="col-4 text-center">
          <p className="summary-heading">Terminals</p>
          <div className="summary">
            <p className="summary-amount terminal-success">
              {status.data && status.data.utilized_terminals.toLocaleString()}
            </p>
            <p className="success-percent">
              <span>
                {status.data && status.data.utilized_terminals_percent}%
              </span>
              Utilized Terminals
            </p>
            <div className="circle-bar-area">
              <CircleProgressBar
                size={180}
                value={status.data && status.data.utilized_terminals_percent}
                strokeWidth={45}
                circleOneStroke="#DDDDDD"
                circleTwoStroke="#406A99"
                centerValue={
                  status.data &&
                  abbreviate(
                    status.data.utilized_terminals +
                      status.data.non_utilized_terminals
                  )
                }
              />
            </div>
            <p className="summary-amount terminal-failed">
              {status.data &&
                status.data.non_utilized_terminals.toLocaleString()}
            </p>
            <p className="failed-percent">
              <span>
                {status.data && status.data.non_utilized_terminals_percent}%
              </span>
              Non-Utilized Terminals
            </p>
          </div>
        </div>
      </div>
      <div className="label ms-5 mt-2">
        <div>
          <div className="d-flex flex-row">
            <div className="data-label value mt-1 me-2"></div>
            <p>Value</p>
          </div>
        </div>
        <div>
          <div className="d-flex flex-row">
            <div className="data-label volume mt-1 me-2"></div>
            <p>Volume</p>
          </div>
        </div>
        <div>
          <div className="d-flex flex-row">
            <div className="data-label terminal mt-1 me-2"></div>
            <p>Terminals</p>
          </div>
        </div>
      </div>
    </div>
  );
}
