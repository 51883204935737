import React, { useState, useEffect, useCallback } from "react";
import Table from "../../../components/Table";
import GetData from "../../../api/GetData";
import AppData from "../../../config/appData.json";
import Pagination from "../../../components/Pagination";
import axios from "axios";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import handlePostApi from "../../../api/HandlePostApi";
import { ToastContainer, toast } from "react-toastify";
import Modal from "../../../components/modal/Modal";
import CustomSelect from "../../../components/customSelect/CustomSelect";

export default function ChargeProfiling() {
  const [message, setMessage] = useState("");
  const [riskList, setRiskList] = useState([]);
  const [statusVol, setStatusVol] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const postPerPage = 50;
  const lastPageNum = Math.ceil(statusVol / postPerPage);
  const pageStart = (pageNumber - 1) * postPerPage + 1;

  const handleCreateBand = async () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const toggleStatus = async (userId, status) => {
    try {
      const endpoint = AppData.PAYMENT_URL + `settings/switch/${userId}`;
      // console.log("status", status, !status);
      const payload = {
        status: !status,
      };
      await axios.patch(endpoint, payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      });
    } catch (error) {
      console.log("Error toggling staatus", error);
    } finally {
      fetchHistoryData();
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "S/N",
        Cell: (row) => {
          return <div>{row.row.index + pageStart}</div>;
        },
      },
      {
        Header: "Switch Name",
        accessor: "name",
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: (row) => {
          const rowData = row.row.original;
          const { status, _id } = rowData;

          const handleStatusToggle = () => {
            toggleStatus(_id, status);
          };

          return (
            <label className="switch" htmlFor={`enabled-${row.row.index}`}>
              <input
                type="checkbox"
                className="checkbox"
                name="enabled"
                id={`enabled-${row.row.index}`}
                checked={status}
                onChange={handleStatusToggle}
              />
              <span className="slider" />
              <span className="inner" />
            </label>
          );
        },
      },
      {
        Header: "Date Created",
        accessor: (date) => {
          let dDate = date.createdAt;
          const formatDate = new Date(dDate);
          return <div> {formatDate.toLocaleString()}</div>;
        },
      },
      {
        Header: "Last Updated",
        accessor: (date) => {
          let dDate = date.updatedAt;
          const formatDate = new Date(dDate);
          return <div> {formatDate.toLocaleString()}</div>;
        },
      },
    ],
    [pageNumber]
  );

  const fetchHistoryData = async () => {
    try {
      const endpoint = AppData.PAYMENT_URL + "charge";
      const response = await GetData(endpoint);
      // console.log("charge response", response);
      setRiskList(response);
    } catch (error) {
      console.log("Error fetching data", error);
    }
  };

  function formatDate(date) {
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  }

  const handleFilter = (e) => {
    e.preventDefault();
    fetchHistoryData();
  };

  useEffect(() => {
    fetchHistoryData();
  }, [pageNumber]);

  useEffect(() => {
    if (message?.trim().length > 0) {
      toast(message);
      setMessage(""); // Clear the message after displaying the toast
    }
    fetchHistoryData();
  }, [message]);

  return (
    <div className="band-config">
      <div className="add-config">
        <button className="add-config-btn" onClick={handleCreateBand}>
          Add Band
        </button>
      </div>
      <div className="table-responsive">
        {riskList.data && riskList.data.length > 0 ? (
          <Table
            columns={columns}
            data={riskList.data}
            enableRowSelection={false}
            tableClass={"table table-borderless table-striped mb-0"}
          />
        ) : (
          <p>No record found</p>
        )}
        {lastPageNum > 1 && (
          <Pagination
            prevPage={pageNumber - 1}
            nextPage={pageNumber + 1}
            totalPages={lastPageNum}
            hasNextPage={pageNumber < lastPageNum}
            hasPrevPage={pageNumber > 1}
            setPageNum={setPageNumber}
          />
        )}
      </div>
      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          onClose={closeModal}
          content={
            <CreateCharge
              setMessage={setMessage}
              setIsModalOpen={setIsModalOpen}
            />
          }
        />
      )}
      <ToastContainer />
    </div>
  );
}

const CreateCharge = ({ setMessage, setIsModalOpen }) => {
  const [selectedCardScheme, setSelectedCardScheme] = useState("");
  const [userId, setUserId] = useState("");
  const [bandId, setBandId] = useState("");

  const schema = yup.object().shape({
    card_scheme: yup.string().default(selectedCardScheme),
    charges: yup
      .number()
      .typeError("Charge must be a number")
      .required("Charge is required"),
    type: yup.string().required("Charge type is required"),
    user_id: yup.string().default(userId),
    band_id: yup.string().default(bandId),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    try {
      const endpoint = AppData.PAYMENT_URL + "charge/create";
      const response = await handlePostApi(endpoint, data);
      setMessage(response.data.message || "Successfully created a band");
      setIsModalOpen(false);
    } catch (error) {
      console.error("Error submitting data:", error);
      setIsModalOpen(false);
      setMessage(error?.response?.data?.error || "Error occured");
    }
  };

  return (
    <form className="charge-profile-modal" onSubmit={handleSubmit(onSubmit)}>
      <h4>Create Charge Profile</h4>
      <div className="mb-3">
        <CustomSelect
          heading="Select Card Scheme"
          selectedVal={
            selectedCardScheme ? selectedCardScheme : "Select Card Scheme"
          }
          setSelectedValue={setSelectedCardScheme}
          items={[{ name: "Mastercard" }, { name: "Visa" }, { name: "Verve" }]}
        />
      </div>
      <div
        className={
          errors.charges
            ? "grouped-input grouped-input-error mb-4"
            : "grouped-input mb-3"
        }
      >
        <label className="col-12" htmlFor="charges">
          Charge:
        </label>
        <input
          className="form-control"
          type="text"
          id="charges"
          {...register("charges")}
        />
        <small>{errors.charges?.message}</small>
      </div>

      <div
        className={
          errors.type
            ? "grouped-input grouped-input-error mb-4"
            : "grouped-input mb-3"
        }
      >
        <label className="col-12" htmlFor="type">
          Type:
        </label>
        <input
          className="form-control"
          type="text"
          id="type"
          {...register("type")}
        />
        <small>{errors.type?.message}</small>
      </div>

      <div>
        <button type="submit" className="update-btn">
          Create
        </button>
      </div>
    </form>
  );
};
