import React, { useState } from "react";
import "./ResetPassword.css";
import Images from "../../assets/images/Images";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import AppData from "../../config/appData.json";
import PostData from "../../api/PostData";
import handlePostApi from "../../api/HandlePostApi";
import PasswordChecker from "../../components/PasswordChecker";

export default function ResetPassword({
  changePassSuccess,
  setChangePassSuccess,
}) {
  const [msg, setMsg] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [pwdChecker, setPwdChecker] = useState(false);
  const email = localStorage.getItem("email");
  const [checks, setChecks] = useState({
    capsLetterCheck: false,
    smallLetterCheck: false,
    numberCheck: false,
    pwdLengthCheck: false,
    specialCharCheck: false,
  });

  yup.addMethod(yup.string, "stripEmptyString", function () {
    return this.transform((value) => (value === "" ? undefined : value));
  });

  const emailRegex = /[^]*@[^]*\..+/;
  const passwordRegex = /.*[!@#$%^&*(),.?":{}|<>]/;

  const schema = yup.object().shape({
    email: yup
      .string()
      .stripEmptyString()
      .default(email)
      .matches(emailRegex, "Invalid email format"),
    pin: yup.string().required("PIN is required!"),
    password: yup
      .string()
      .min(6, "Password must be at least 6 characters!")
      .required("Password field cannot be empty!")
      .matches(/[a-z]/, "Password must contain a lowercase letter")
      .matches(/[A-Z]/, "Password must contain an uppercase letter")
      .matches(/\d/, "Password must contain a number")
      .matches(passwordRegex, "Password must contain a special character"),
    password_confirmation: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords don't match")
      .required("Password confirmation field cannot be empty!"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    criteriaMode: "all",
    reValidateMode: "onChange",
    mode: "onChange",
  });

  const handleKeyUp = (e) => {
    const { value } = e.target;
    const smallLetterCheck = /[a-z]/.test(value);
    const capsLetterCheck = /[A-Z]/.test(value);
    const numberCheck = /[0-9]/.test(value);
    const pwdLengthCheck = value.length >= 6;
    const specialCharCheck = /[!@#$%^&*(),.?":{}|<>']/.test(value);
    setChecks({
      capsLetterCheck,
      smallLetterCheck,
      numberCheck,
      pwdLengthCheck,
      specialCharCheck,
    });
  };

  const obscureEmail = (email) => {
    const [name, domain] = email.split("@");
    return `${name[0]}${new Array(name.length).join("*")}@${domain}`;
  };

  const changedEmail = obscureEmail(email);

  const handleFormSubmit = async (requestData) => {
    try {
      const requestUrl = AppData.VAS_URL + "auth/change-password";
      await PostData(requestUrl, requestData);
      setChangePassSuccess(true);
    } catch (error) {
      console.log("error", error);
      setMsg(error?.response?.data?.message || "Error processing request");
    }
  };

  const handleResendPin = async () => {
    try {
      const url = AppData.VAS_URL + "auth/send-reset-code";
      const requestData = { email: email };
      await handlePostApi(url, requestData);
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <>
      {!changePassSuccess ? (
        <div className="change-pass">
          <form className="form" onSubmit={handleSubmit(handleFormSubmit)}>
            <h1>Choose A New Password</h1>
            {msg && <small>{msg}</small>}
            <div className="mb-3 mt-3 text-start">
              <label htmlFor="login" className="form-label">
                Pin
              </label>
              <input
                className={errors.pin ? "form-control error" : "form-control"}
                type="text"
                placeholder="Enter pin"
                name="pin"
                {...register("pin")}
              />
              <small>{errors.pin?.message}</small>
            </div>
            <div className="mb-3 mt-3 text-start position-relative">
              <label htmlFor="login" className="form-label">
                New Password
              </label>
              <input
                className={
                  errors.password ? "form-control error" : "form-control"
                }
                type={showPassword ? "text" : "password"}
                placeholder="•••••••••••••••"
                name="pswd"
                onFocus={() => setPwdChecker(true)}
                onBlur={() => setPwdChecker(false)}
                onKeyUp={handleKeyUp}
                {...register("password")}
              />
              <img
                className="pass-view"
                src={Images.show}
                alt="pass"
                onClick={() => setShowPassword(!showPassword)}
              />
              <small>{errors.password?.message}</small>
            </div>
            <div className="mb-3 mt-3 text-start position-relative">
              <label htmlFor="login" className="form-label">
                Confirm New Password
              </label>
              <input
                className={
                  errors.password_confirmation
                    ? "form-control error"
                    : "form-control"
                }
                type={showConfirmPassword ? "text" : "password"}
                placeholder="•••••••••••••••"
                name="pswd"
                {...register("password_confirmation")}
              />
              <img
                className="pass-view"
                src={Images.show}
                alt="pass"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              />
              <small>{errors.password_confirmation?.message}</small>
              {pwdChecker && <PasswordChecker checks={checks} />}
              <div className="mt-3 text-center">
                <button type="submit" className="btn">
                  Reset Password
                </button>
                <p>
                  Didn&apos;t get the email?{" "}
                  <span>
                    <button
                      className="resend"
                      type="button"
                      onClick={handleResendPin}
                    >
                      Send another email please
                    </button>
                  </span>
                </p>
              </div>
            </div>
          </form>
        </div>
      ) : (
        <div className="pass-confirmation">
          <form>
            <h2>Password Reset Successfully</h2>
            <p className="sub-header">All done! we have sent and email to</p>
            <p className="display-email">{changedEmail}</p>
            <div className="mt-3">
              <a href="/signin">
                <button type="button" className="btn">
                  Return To Login
                </button>
              </a>
            </div>
          </form>
        </div>
      )}
    </>
  );
}
