import React, { useState, useEffect } from "react";
import "./Reporting.css";
import { useNavigate } from "react-router-dom";
import AppData from "../../../config/appData.json";
import images from "../../../assets/images/Images";
import transaction from "../../../assets/images/total-trans.png";
import success from "../../../assets/images/total-success.png";
import fail from "../../../assets/images/total-failed.png";
import term from "../../../assets/images/utilized-term.png";
import pending from "../../../assets/images/pending-status.png";
import online from "../../../assets/images/online.png";
import allStatus from "../../../assets/images/total-agent.png";
import ReportingChart from "./ReportingChart";
import GetData from "../../../api/GetData";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import HandlePostApi from "../../../api/HandlePostApi";
import DateSelector from "./DataSelector";
import CardSchemePerformance from "./CardSchemePerformance";
import ValueAddedServiceSummary from "./ValueAddedServiceSummary";
import SimpleLoader from "../../../components/spinner/SimpleLoader";
import FormatAmount from "../../../helpers/FormatAmount";

const ReportingCard = ({
  amountLabel,
  amount,
  icon,
  className,
  volumeLabel,
  volume,
  sign,
}) => {
  return (
    <div className="col-3">
      <div className={className}>
        <div className="icon">
          <img src={icon} alt="" />
        </div>
        <h1>{amountLabel}</h1>
        {amount ? (
          <p className="amount">
            <span>{sign}</span>
            {amount}
          </p>
        ) : (
          <SimpleLoader />
        )}
        <div>
          <h2>{volumeLabel}</h2>
          <p className="volume">{volume}</p>
        </div>
      </div>
    </div>
  );
};

const chartState = {
  labels: ["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"],
  datasets: [
    {
      label: "Failed - Withdrawal",
      borderColor: "transparent",
      borderWidth: 2,
      barThickness: 8,
      maxBarThickness: 12,
      stack: "Withdrawal",
      backgroundColor: "#A0A0A0",
      data: [6, 0, 0, 3, 1, 2, 5],
    },
    {
      label: "Success - Withdrawal",
      borderColor: "transparent",
      borderRadius: 12,
      borderWidth: 2,
      barThickness: 8,
      maxBarThickness: 12,
      stack: "Withdrawal",
      backgroundColor: "#0F8CFF",
      data: [15, 16, 17, 18, 19, 20, 21],
    },
    {
      label: "Failed - Transfer",
      borderColor: "transparent",
      borderWidth: 2,
      barThickness: 8,
      maxBarThickness: 12,
      stack: "Transfer",
      backgroundColor: "#A0A0A0",
      data: [5, 9, 6, 3, 0, 4, 5],
    },
    {
      label: "Success - Transfer",
      borderColor: "transparent",
      borderRadius: 12,
      borderWidth: 2,
      barThickness: 8,
      maxBarThickness: 12,
      stack: "Transfer",
      backgroundColor: "#00E59E",
      data: [15, 16, 17, 18, 19, 20, 21],
    },
    {
      label: "Failed - Bill",
      borderColor: "transparent",
      borderWidth: 2,
      barThickness: 8,
      maxBarThickness: 12,
      stack: "Bill",
      backgroundColor: "#A0A0A0",
      data: [0, 4, 7, 5, 3, 3, 0],
    },
    {
      label: "Success - Bill",
      borderColor: "transparent",
      borderRadius: 12,
      borderWidth: 2,
      barThickness: 8,
      maxBarThickness: 12,
      stack: "Bill",
      backgroundColor: "#0F0E7D",
      backgroundColorHover: "#3e95cd",
      data: [10, 11, 12, 13, 14, 15, 16],
    },
  ],
};

export default function Reporting() {
  const navigate = useNavigate();
  var abbreviate = require("number-abbreviate");
  const days = ["Today", "Yesterday", "Present Week"];
  const [clickIndex, setClickIndex] = useState(0);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [pageNum, setPageNum] = useState(1);
  const [status, setStatus] = useState("");
  const [terminal, setTerminal] = useState("");
  const [cardScheme, setCardScheme] = useState("");
  const [topPerformingBanks, setTopPerformingBanks] = useState("");
  const [transCounts, setTransCounts] = useState("");
  const [msg, setMsg] = useState("");
  const [vasSummary, setVasSummary] = useState([]);

  const formattedStartDate =
    startDate.getFullYear() +
    "-" +
    parseInt(startDate.getMonth() + 1) +
    "-" +
    startDate.getDate();
  const formattedEndDate =
    endDate.getFullYear() +
    "-" +
    parseInt(endDate.getMonth() + 1) +
    "-" +
    endDate.getDate();

  const imageURL = {
    MASTERCARD: images.mastercard,
    VERVE: images.verve,
    VISA: images.visa,
  };

  const handleDateFunc = (index) => {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    const getStartOfWeek = (date) => {
      const day = date.getDay();
      const diff = date.getDate() - day + (day === 0 ? -6 : 1);
      return new Date(date.setDate(diff));
    };

    let startDate = today;
    let endDate = today;

    switch (index) {
      case 0:
        startDate = today;
        endDate = today;
        break;
      case 1:
        startDate = yesterday;
        endDate = yesterday;
        break;
      case 2:
        startDate = getStartOfWeek(today);
        break;
      default:
        startDate = today;
        endDate = today;
    }

    setStartDate(startDate);
    setEndDate(endDate);
    setClickIndex(index);
  };

  const handleApiError = (error) => {
    if (error.response && error.response.data) {
      const err = error.response.data.message;
      console.error(error);
      if (err && err === "Expired Session, Please Login Again") {
        navigate("/signin");
      } else {
        setMsg(error.response.data.message);
      }
    } else {
      // Handle the error when response or data is undefined
      console.error("Error response or data is undefined:", error);
    }
  };

  const fetchData = async () => {
    try {
      const url = AppData.WALLET_URL + "admin/transaction-history";
      const requestData = {
        page: pageNum,
        startDate: startDate,
        endDate: endDate,
        status: "",
        walletId: "",
        reference: "",
        product: "",
        account: "",
        channel: "",
        provider: "",
      };
      const response = await HandlePostApi(url, requestData);
      setVasSummary(response.summary);
    } catch (error) {
      handleApiError(error);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchData();
    GetData(
      AppData.BASE_URL +
        `transactions/stats?startdate=${formattedStartDate}&enddate=${formattedEndDate}`
    )
      .then((response) => {
        setStatus(response.data);
      })
      .catch(handleApiError);
    GetData(AppData.BASE_URL + "terminals/stats")
      .then((response) => {
        setTerminal(response.data);
      })
      .catch(handleApiError);
    GetData(
      AppData.BASE_URL +
        `banks/get-card-scheme-percent?range=range&startdate={2020-12-1&}&enddate=${formattedEndDate}`
    )
      .then((response) => {
        setCardScheme(response.data);
      })
      .catch(handleApiError);
    GetData(
      AppData.BASE_URL +
        `banks/get-top-performing?range=range&startdate=2020-12-1&enddate=2023-04-1`
    )
      .then((response) => {
        setTopPerformingBanks(response.data);
      })
      .catch(handleApiError);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    GetData(AppData.WALLET_URL + `admin/get-counts`)
      .then((response) => {
        setTransCounts(response.data);
      })
      .catch(handleApiError);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const reportingDays = ["Today", "Yesterday", "Present Week"];

  return (
    <div className="reporting">
      <main className="col reporting-container">
        <DateSelector
          days={reportingDays}
          clickIndex={clickIndex}
          handleDateFunc={handleDateFunc}
        />
        <div className="row reporting-summary">
          <div className="col">
            <div className="row">
              <ReportingCard
                amountLabel="Total Transaction"
                sign={"₦"}
                amount={FormatAmount(status?.total_value)}
                icon={transaction}
                className="reporting-card"
                volumeLabel="Volume"
                volume={status && status.total_volume}
              />
              <ReportingCard
                amountLabel="Total Successful"
                sign={"₦"}
                amount={FormatAmount(status?.success_value)}
                icon={success}
                className="reporting-card successful"
                volumeLabel="Volume"
                volume={status && status.success_count}
              />
              <ReportingCard
                amountLabel="Total Failed"
                sign={"₦"}
                amount={FormatAmount(status?.failed_value)}
                icon={fail}
                className="reporting-card failure"
                volumeLabel="Volume"
                volume={status && status.failed_count}
              />
              <ReportingCard
                amountLabel="Utilized Terminal"
                amount={status && status.utilized_terminals}
                icon={term}
                className="reporting-card utilize"
                volumeLabel="Non-Utilized Terminal"
                volume={status && status.non_utilized_terminals}
              />
            </div>
            <div className="row mb-3">
              <div className="col-3 mt-3">
                <div className="status-trans pending">
                  <div className="icon">
                    <img src={pending} alt="" />
                  </div>
                  <h1>PENDING TRANSACTIONS</h1>
                  {vasSummary.pendingCount ? (
                    <p className="amount">
                      {vasSummary.pendingCount.toLocaleString()}
                    </p>
                  ) : (
                    <SimpleLoader />
                  )}
                </div>
              </div>
              <div className="col-3 mt-3">
                <div className="status-trans online">
                  <div className="icon">
                    <img src={online} alt="" />
                  </div>
                  <h1>ONLINE TERMINALS</h1>
                  {terminal ? (
                    <p className="amount">{terminal.onlineTerminals}</p>
                  ) : (
                    <SimpleLoader />
                  )}
                </div>
              </div>
              <div className="col-3 mt-3">
                <div className="status-trans agents">
                  <div className="icon">
                    <img src={allStatus} alt="" />
                  </div>
                  <h1>TOTAL AGENTS</h1>
                  {transCounts ? (
                    <p className="amount">{transCounts.agents}</p>
                  ) : (
                    <SimpleLoader />
                  )}
                </div>
              </div>
              <div className="col-3 mt-3">
                <div className="status-trans wallet">
                  <div className="wallet-trans">
                    <h1>TOTAL WALLET</h1>
                    {transCounts ? (
                      <p>{transCounts.totalWallets}</p>
                    ) : (
                      <SimpleLoader />
                    )}
                  </div>
                  <div className="analysis"></div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-9 mb-3">
                <div className="chart">
                  <ReportingChart state={chartState} />
                </div>
              </div>
              <div className="col-3 mb-3">
                <div className="top-bank">
                  <h6>Top Performing Banks</h6>
                  {topPerformingBanks &&
                    topPerformingBanks.map((bank, index) => (
                      <div key={index} className="bank-info">
                        <img src={transaction} alt="" />
                        <div className="percentage">
                          <div className="label">
                            <p className="bank-name">{bank.bankName}</p>
                            <p>{`${Math.ceil(bank.percent)}%`}</p>
                          </div>
                          <div className="progress">
                            <div
                              className="progress-bar"
                              role="progressbar"
                              aria-valuenow="70"
                              aria-valuemin="0"
                              aria-valuemax="100"
                              style={{ width: `${bank.percent}%` }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
              {cardScheme && (
                <CardSchemePerformance
                  cardScheme={cardScheme}
                  imageURL={imageURL}
                />
              )}
            </div>
          </div>
          <div className="col-3 mb-3">
            <h3>Value Added Service Transactions</h3>
            <ValueAddedServiceSummary
              vasSummary={vasSummary}
              transCounts={transCounts}
            />
          </div>
        </div>
      </main>
    </div>
  );
}
